export const columns = 7;

export const rows = 6;

/**
 * Initial board width and height
 */
export const initialWidth = 946;
export const initialHeight = 580;
export const ratio = initialWidth / initialHeight;
