import { atom } from 'recoil';
import { resetRecoil } from 'recoil-nexus';

import { GameType } from '@/framework/game';

import { userPlayerState, opponentPlayerState } from './playersState';
import { boardStates } from './boardState';

export const gameIdState = atom<string | undefined>({
  key: 'gameIdState',
  default: undefined,
});

export const gameTypeState = atom<GameType | undefined>({
  key: 'gameTypeState',
  default: undefined,
});

export const gameStates = [
  gameIdState,
  gameTypeState,
  ...Object.values(userPlayerState),
  ...Object.values(opponentPlayerState),
  ...boardStates,
] as any;

export const resetGame = () => {
  gameStates.forEach(resetRecoil);
};
