import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import * as cookies from 'browser-cookies';

import { gameIdState } from '@/state/gameState';

import { useReconnectGame, useExitGame, useJoinGame } from '@/use/useGame';

const Game = () => {
  const reconnectGame = useReconnectGame();
  const joinGame = useJoinGame();
  const { disconnectGame } = useExitGame();
  const { gameId: urlGameId } = useParams();
  const gameId = useRecoilValue(gameIdState);

  useEffect(() => {
    if (urlGameId) {
      if (urlGameId === gameId) {
        console.log('App url change: same ID, do nothing');
        return;
      }

      if (cookies.get(`${urlGameId}_sesh`)) {
        reconnectGame(urlGameId);
      } else {
        joinGame(urlGameId);
      }
    }

    return () => {
      console.log('App url change: clear game');
      disconnectGame();
    };
  }, [urlGameId]);

  return null;
};

export default Game;
