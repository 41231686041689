import React from 'react';
import Typography from '@/ui-components/Typography';
import BaseButton, { IButtonBaseProps } from '@/ui-components/BaseButton';

const Link = ({ children, className, ...rest }: IButtonBaseProps) => {
  return (
    <BaseButton className={`link ${className}`} {...rest}>
      <Typography variant="m" component="span">
        {children}
      </Typography>
    </BaseButton>
  );
};

export default Link;
