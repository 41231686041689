import { atom } from 'recoil';
import { resetRecoil } from 'recoil-nexus';

import { BoardCell } from '@/framework/board';
import { IPlayer2 } from '@/framework/player';

import {
  GamePhaseType,
  EndgameReason,
  WinningCombinationType,
  Score,
} from '@/framework/game';

import { columns, rows } from '@/constants';

export const turnState = atom<IPlayer2['id'] | undefined>({
  key: 'turnState',
  default: undefined,
});

export const gamePhaseState = atom<GamePhaseType | undefined>({
  key: 'gamePhaseState',
  default: undefined,
});

export const boardState = [...Array(columns).keys()].map((cIndex) =>
  [...Array(rows).keys()].map((rIndex) =>
    atom<BoardCell | undefined>({
      key: `boardState${cIndex}${rIndex}`,
      default: undefined,
    }),
  ),
);

export const winnerIdState = atom<IPlayer2['id'] | undefined>({
  key: 'winnerIdState',
  default: undefined,
});

export const endgameReasonState = atom<EndgameReason | undefined>({
  key: 'endgameReasonState',
  default: undefined,
});

export const winningComboState = atom<WinningCombinationType | undefined>({
  key: 'winningComboState',
  default: undefined,
});

export const endgameScoreChangeState = atom<Score | undefined>({
  key: 'endgameScoreChangeState',
  default: undefined,
});

export const boardStates = [
  turnState,
  gamePhaseState,
  winnerIdState,
  endgameReasonState,
  winningComboState,
  endgameScoreChangeState,
  ...boardState.reduce((a, b) => {
    return [...a, ...b];
  }),
] as any;

export const rematchStates = [
  winnerIdState,
  endgameReasonState,
  winningComboState,
  endgameScoreChangeState,
  ...boardState.reduce((a, b) => {
    return [...a, ...b];
  }),
] as any;

export const resetBoard = () => {
  boardStates.forEach(resetRecoil);
};

export const resetBoardRematch = () => {
  rematchStates.forEach(resetRecoil);
};
