import React from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import SquareButton from '@/ui-components/SquareButton';
import Icon from '@/ui-components/Icon';
import Logo from '@/ui-components/Logo';

import useBreakpoint from '@/use/useBreakpoint';
import useBoardSide from '@/use/useBoardSide';
import useLayout from '@/use/useLayout';
import useBem from '@/use/useBem';
import { openModal } from '@/use/useModal';
import { isMenuOpenState } from '@/use/useMenuState';
import { gameIdState } from '@/state/gameState';
import { gamePhaseState } from '@/state/boardState';
import { useExitGame } from '@/use/useGame';
import { closeModal } from '@/use/useModal';

import { userSelector } from '@/framework/user';

const Nav = () => {
  const navigate = useNavigate();
  const loggedIn = useRecoilValue(userSelector.loggedIn);
  const { breakpoint } = useBreakpoint();
  const boardSide = useBoardSide();
  const gameId = useRecoilValue(gameIdState);
  const gamePhase = useRecoilValue(gamePhaseState);
  const { layout } = useLayout();
  const { bem } = useBem('nav');
  const { exitGame } = useExitGame();
  const isMenuOpen = useRecoilValue(isMenuOpenState);

  const width = layout === 'landscape' ? boardSide.width : 'auto';

  const handleMenuClick = () => {
    if (gameId && gamePhase !== 'over') {
      openModal('confirm-exit');
    } else {
      exitGame();
      closeModal();
      navigate('/');
    }
  };

  const renderLogo = () => {
    if (layout === 'desktop') {
      return null;
    }

    const size = breakpoint.t || breakpoint.m ? 'small' : 'default';

    return (
      <div className={bem('logo')} style={{ width }}>
        <Logo {...{ size }} />
      </div>
    );
  };

  const renderAccount = () => {
    if (gameId) {
      return;
    }

    return (
      <div className={bem('button', 'account')} style={{ width }}>
        {loggedIn ? (
          <SquareButton to="/profile" icon={<Icon name="user" />}>
            Profile
          </SquareButton>
        ) : (
          <SquareButton to="/login" icon={<Icon name="user" />}>
            Login
          </SquareButton>
        )}
      </div>
    );
  };

  const renderLeaderboard = () => {
    if (layout === 'desktop') {
      return null;
    }

    return (
      <div className={bem('button', 'leaderboard')} style={{ width }}>
        <SquareButton
          onClick={() => {
            openModal('leaderboard');
          }}
          icon={<Icon name="crown" />}
        >
          Leaderboard
        </SquareButton>
      </div>
    );
  };

  const renderSecondaryMenu = () => {
    if (layout !== 'desktop' && !isMenuOpen) {
      return null;
    }

    return (
      <>
        {renderAccount()}
        {renderLeaderboard()}
      </>
    );
  };

  return (
    <div className={bem('', [layout, { menuState: isMenuOpen }])}>
      {renderLogo()}
      {renderSecondaryMenu()}

      <div className={bem('button', 'menu')} style={{ width }}>
        <SquareButton onClick={handleMenuClick} icon={<Icon name="list" />}>
          Menu
        </SquareButton>
      </div>
    </div>
  );
};

export default Nav;
