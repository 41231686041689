import { useMemo } from 'react';
import { useWindowSize } from '@react-hook/window-size';

import useLayout from '@/use/useLayout';

const initialWidth = 920;
const initialHeight = 540;
const portraitNavSpace = 80;

/**
 * Board - working area of the game, includes:
 * - Presentational elements:
 *   - Border
 *   - Gui
 *
 * Play elements
 *  - Grid
 */

const useBoard = () => {
  const [ww, wh] = useWindowSize();
  const { layout } = useLayout();

  const width = useMemo(() => {
    if (layout === 'desktop') {
      return initialWidth;
    }

    return ww;
  }, [ww, layout]);

  const height = useMemo(() => {
    if (layout === 'desktop') {
      return initialHeight;
    }

    if (layout === 'portrait') {
      return wh - portraitNavSpace;
    }

    return wh;
  }, [wh, layout]);

  const x = ww / 2;

  const y = useMemo(() => {
    if (layout === 'portrait') {
      return (wh + portraitNavSpace) / 2;
    }

    return wh / 2;
  }, [layout, wh]);

  return {
    width,
    height,
    x,
    y,
  };
};

export default useBoard;
