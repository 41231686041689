// authAxios.js
import axios from 'axios';

import { logout } from '@/framework/user';

let host = window.location.hostname;

if (['localhost', '0.0.0.0', '127.0.0.1'].includes(host)) {
  host = `http://0.0.0.0:8000`;
} else {
  host = `https://c-4.io/api`;
}

const axiosClient = axios.create({
  baseURL: host,
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status;

    /**
     * User made an illegal request, or the token has expired
     */
    if ([401, 403].indexOf(status) !== -1) {
      logout();
    }

    return Promise.reject(error.response.data);
  },
);

export default axiosClient;
