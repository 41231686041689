import React from 'react';
import classNames from 'classNames';

import BaseButton, { IButtonBaseProps } from '@/ui-components/BaseButton';

import useBem from '@/use/useBem';

const ButtonRound = ({
  className = '',
  children,
  ...rest
}: IButtonBaseProps) => {
  const { bem } = useBem('button-round');

  return (
    <BaseButton {...rest} className={classNames([bem(''), className])}>
      <div className={bem('content')}>{children}</div>
    </BaseButton>
  );
};

export default ButtonRound;
