import colyseusClient from '@/colyseus/colyseus-client';
import * as cookies from 'browser-cookies';

export const join = async (gameId: string) => {
  const room = await colyseusClient.joinById(gameId);

  cookies.set(`${gameId}_sesh`, room.id, { expires: 1 });
  cookies.set(`${gameId}_userCookieId`, room.sessionId, { expires: 1 });

  return room;
};

export const reconnect = async (gameId: string) => {
  const sessionId = cookies.get(`${gameId}_userCookieId`);

  if (!sessionId) throw `Couldn't connect, session expired`;

  const room = await colyseusClient.reconnect(gameId, sessionId);

  return room;
};
