import React from 'react';
import classNames from 'classNames';

import { GemType, GemState } from '@/framework/gem';

import useBem from '@/use/useBem';

import gemAssets from './gemAssets';

export interface GemProps {
  type?: GemType;
  state?: GemState;
  size?: 'default' | 'small';
  className?: string;

  /** If true sets gems style to loading */
  loading?: boolean;
  /** Adds a halo behind the gem */
  halo?: boolean;
}

const Gem: React.FC<GemProps> = (props) => {
  const {
    type = 'neutral',
    className,
    size = 'default',
    halo,
    state = 'normal',
  } = props;
  const { bem } = useBem('gem');
  const asset = gemAssets[type][state];
  const src = asset[size]['1x'];
  const srcSet = `${asset[size]['2x']} 2x`;

  return (
    <div className={classNames(bem(''), className)}>
      <img {...{ src, srcSet }} />
      {halo && <div className={bem('halo')} />}
    </div>
  );
};

export default Gem;
