import { Room } from 'colyseus.js';
import { getRecoil, setRecoil } from 'recoil-nexus';

import * as profile from '@/api/profile.service';

import {
  boardState,
  turnState,
  gamePhaseState,
  winnerIdState,
  endgameReasonState,
  winningComboState,
  endgameScoreChangeState,
  resetBoardRematch,
} from '@/state/boardState';

import { userState, userSelector } from '@/framework/user';

import { userPlayerState, opponentPlayerState } from '@/state/playersState';

import { pidState } from '@/use/usePidState';
import { openModal, closeModal } from '@/use/useModal';

import { BoardCell } from '@/framework/board';
import { GamePhaseType } from '@/framework/game';

import { getPidFromChanges } from './utils';

let colyseusRoom: Room | null = null;

export const setupRoom: any = (room: any, onStarted?: () => void) => {
  colyseusRoom = room;

  const pid = getRecoil(pidState);

  room.state.players.onAdd = async (colyPlayer: any) => {
    const { colour, connected, userId, rematch, name, id, rejoin } = colyPlayer;
    const playerState = id === pid ? userPlayerState : opponentPlayerState;

    setRecoil(playerState.colour, colour);
    setRecoil(playerState.connected, connected);
    setRecoil(playerState.userId, userId);
    setRecoil(playerState.rematch, rematch);
    setRecoil(playerState.name, name);
    setRecoil(playerState.id, id);
    setRecoil(playerState.rejoin, rejoin);

    if (userId === 'anon') {
      // IF anon, set to "Anonymous"
      setRecoil(playerState.name, name);
    } else {
      if (id === pid && getRecoil(userSelector.loggedIn)) {
        setRecoil(playerState.name, getRecoil(userState.username));
        setRecoil(playerState.score, getRecoil(userState.score));
        setRecoil(playerState.country, getRecoil(userState.country));
      } else {
        try {
          const { data } = await profile.getUserById(userId);

          setRecoil(playerState.name, data.username);
          setRecoil(playerState.score, data.score);
          setRecoil(playerState.country, data.country);
        } catch (err) {
          // IF couldn't find the user, set to "Anonymous"
          setRecoil(playerState.name, name);
        }
      }
    }

    colyPlayer.onChange = (changes: any) => {
      console.log(`player on change:`);

      changes.forEach((change: any) => {
        if (change.field === 'connected') {
          setRecoil(playerState.connected, change.value);
        }

        if (change.field === 'rematch') {
          setRecoil(playerState.rematch, change.value);
        }
        if (change.field === 'rejoin') {
          const mil = change.value - new Date().valueOf();
          const seconds = Math.floor(mil / 1000);
          const rejoin = seconds <= 0 ? undefined : seconds;

          setRecoil(playerState.rejoin, rejoin);
        }
      });
    };
  };

  room.state.listen('turn', (playerId: any) => {
    setRecoil(turnState, playerId);
  });

  room.state.listen('phase', (phase: GamePhaseType) => {
    setRecoil(gamePhaseState, phase);

    if (phase === 'over') {
      openModal('endgame', {
        extra: { animate: true },
      });
    }
  });

  room.state.board.onAdd = (column: any, cIndex: number) => {
    column.slots.onAdd = function (slot: any, rIndex: number) {
      slot.onChange = function (changes: any) {
        const pid = getPidFromChanges(changes);

        if (pid) {
          setRecoil(boardState[cIndex][rIndex], {
            pid,
          } as BoardCell);
        }
      };
    };
  };

  room.onMessage('restart', () => {
    resetBoardRematch();
    closeModal();
  });

  room.onMessage('started', () => {
    onStarted?.();
  });

  room.state.endGame.onChange = (changes: any) => {
    console.log('changes', changes);

    changes.forEach((change: any) => {
      if (change.field === 'reason') {
        setRecoil(endgameReasonState, change.value);
      }

      if (change.field === 'winnerId') {
        setRecoil(winnerIdState, change.value);
      }

      if (change.field === 'winningCombos') {
        const combo = change.value.map((keys: any) => {
          return [keys.x, keys.y];
        });
        setRecoil(winningComboState, combo);
      }

      if (change.field === 'scores') {
        const id = getRecoil(userState.id);

        change.value.map((score: any) => {
          const { newScore, oldScore, userId } = score;

          if (id === parseInt(userId)) {
            const scoreChange = newScore - oldScore;
            setRecoil(userPlayerState.score, newScore);
            setRecoil(userState.score, newScore);
            setRecoil(endgameScoreChangeState, scoreChange);
          } else {
            setRecoil(opponentPlayerState.score, newScore);
          }
        });
      }
    });
  };
};

export const resign = () => {
  colyseusRoom?.send('resign');
};

export const rematch = () => {
  colyseusRoom?.send('rematch');

  /**
   * 1. colyseusRoom?.send('rematch');
   * 2. Send request to API createRematch(currentGameID) => rematchGameId
   * 3. Join room
   * 4. Wait for the person to join
   * 5. Close the other room, when the person join
   */
};

export const turn = (col: number) => {
  colyseusRoom?.send('turn', { col });
};

export const exitRoom = ({ consented = true } = {}) => {
  const id = colyseusRoom?.id;
  colyseusRoom?.leave(consented);
  colyseusRoom?.removeAllListeners();
  colyseusRoom = null;
};

export default colyseusRoom;
