const requireRetina = (gem) => {
  return {
    default: {
      '1x': require(`@/assets/images/gems/${gem}@2x.png?size=91`),
      '2x': require(`@/assets/images/gems/${gem}@2x.png?size=182`),
    },
    small: {
      '1x': require(`@/assets/images/gems/${gem}@2x.png?size=60`),
      '2x': require(`@/assets/images/gems/${gem}@2x.png?size=120`),
    },
  };
};

const gems = {
  blue: {
    normal: requireRetina('gem-blue'),
    selected: requireRetina('gem-blue-selected'),
    broken: requireRetina('gem-blue-broken'),
  },
  red: {
    normal: requireRetina('gem-red'),
    selected: requireRetina('gem-red-selected'),
    broken: requireRetina('gem-red-broken'),
  },
  neutral: {
    normal: requireRetina('gem-neutral'),
    selected: requireRetina('gem-neutral'),
    broken: requireRetina('gem-neutral'),
  },
};

export default gems;
