import React from 'react';
import classNames from 'classNames';

import useBem from '@/use/useBem';

export type ButtonGroupProps = React.HTMLAttributes<HTMLDivElement> & {};

const ButtonGroup = ({
  children,
  className = '',
  ...rest
}: ButtonGroupProps) => {
  const { bem } = useBem('button-group');

  return (
    <div {...rest} className={classNames([bem(''), className])}>
      {children}
    </div>
  );
};

export default ButtonGroup;
