import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { exitRoom } from '@/colyseus/colyseus-room';

import { resetGame } from '@/state/gameState';
import { isMenuOpenState } from '@/use/useMenuState';

type EndGame = {
  clearUrl?: boolean;
  consented?: boolean;
};

const useExitGame = () => {
  const navigate = useNavigate();
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);

  const endGame = ({ clearUrl, consented }: EndGame = {}) => {
    console.log('end game');
    exitRoom({ consented });
    resetGame();

    if (clearUrl) {
      navigate('/');
    }
    setIsMenuOpen(true);
  };

  /**
   * Clears any current games, without affecting the url.\
   * Could be useful for reconnect / join, where url needs to stay intact
   */
  const clearGame = () => {
    console.log('clearGame');
    endGame();
  };

  /**
   * When player specifically leaves / abandons the game
   */
  const exitGame = () => {
    console.log('exitGame');
    endGame({ clearUrl: true });
  };

  /**
   * When player unintentionally disconnects from the game (url change)
   */
  const disconnectGame = () => {
    console.log('disconnect from  game');
    endGame({ consented: false });
  };

  return { clearGame, exitGame, disconnectGame };
};

export default useExitGame;
