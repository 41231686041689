import React, { useMemo } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { useRecoilValue } from 'recoil';

import SquareButton from '@/ui-components/SquareButton';
import Typography from '@/ui-components/Typography';
import Icon from '@/ui-components/Icon';
import {
  Player,
  PlayerGem,
  PlayerContent,
  PlayerTimer,
} from '@/ui-components/Player';

import useBem from '@/use/useBem';
import useLayout from '@/use/useLayout';
import { gamePhaseState, turnState } from '@/state/boardState';

import { userPlayerState, opponentPlayerState } from '@/state/playersState';

import useBoard from '@/use/useBoard';
import useBoardSide from '@/use/useBoardSide';
import { isMenuOpenState } from '@/use/useMenuState';
import { openModal } from '@/use/useModal';

const GameInterface = () => {
  const [ww] = useWindowSize();
  const { bem } = useBem('game-interface');
  const { layout } = useLayout();

  const userPlayer = {
    id: useRecoilValue(userPlayerState.id),
    userId: useRecoilValue(userPlayerState.userId),
    colour: useRecoilValue(userPlayerState.colour),
    name: useRecoilValue(userPlayerState.name),
    country: useRecoilValue(userPlayerState.country),
    score: useRecoilValue(userPlayerState.score),
  };

  const opponentPlayer = {
    connected: useRecoilValue(opponentPlayerState.connected),
    id: useRecoilValue(opponentPlayerState.id),
    userId: useRecoilValue(opponentPlayerState.userId),
    colour: useRecoilValue(opponentPlayerState.colour),
    name: useRecoilValue(opponentPlayerState.name),
    country: useRecoilValue(opponentPlayerState.country),
    rejoin: useRecoilValue(opponentPlayerState.rejoin),
    score: useRecoilValue(opponentPlayerState.score),
  };

  const isMenuOpen = useRecoilValue(isMenuOpenState);
  const turn = useRecoilValue(turnState);
  const gamePhase = useRecoilValue(gamePhaseState);

  const board = useBoard();
  const boardSide = useBoardSide();

  const style = {
    width: `${board.width}px`,
    height: `${board.height}px`,
    left: `${board.x - board.width / 2}px`,
    top: `${board.y - board.height / 2}px`,
  };

  const sideStyle = {
    width: layout === 'portrait' ? undefined : boardSide.width,
    height: layout === 'portrait' ? boardSide.height : undefined,
  };

  const playerLayout = useMemo(() => {
    if (layout === 'portrait') {
      return 'inline';
    }

    return 'centered';
  }, [layout]);

  const size = useMemo(() => {
    if (boardSide.width < 170 || boardSide.height < 450 || ww < 500) {
      return 'small';
    }

    return undefined;
  }, [boardSide.width, boardSide.height, ww]);

  const renderAction = () => {
    if (gamePhase === 'over') {
      return (
        <SquareButton
          elevation={0}
          onClick={() => {
            openModal('endgame');
          }}
          icon={<Icon name="options" />}
        >
          Options
        </SquareButton>
      );
    }

    return (
      <SquareButton
        elevation={0}
        onClick={() => {
          openModal('resign');
        }}
        icon={<Icon name="flag" />}
      >
        Resign
      </SquareButton>
    );
  };

  const getOpponentMsg = () => {
    if (gamePhase !== 'playing') {
      return null;
    }

    if (opponentPlayer.id === turn) {
      return `Opponent's turn`;
    }
  };

  return (
    <div className={bem('', [{ active: !isMenuOpen }, layout])} {...{ style }}>
      <div className={bem('side', '1')} style={sideStyle}>
        <Player
          turn={userPlayer.id === turn}
          layout={playerLayout}
          onClick={
            userPlayer.userId && userPlayer.userId !== 'anon'
              ? () => {
                  openModal('user-info', {
                    extra: { userId: userPlayer.userId },
                  });
                }
              : undefined
          }
          {...{ size }}
        >
          <PlayerGem type={userPlayer.colour} />
          <PlayerContent
            name={userPlayer.name}
            country={userPlayer.country}
            score={userPlayer.score}
          >
            {gamePhase === 'playing' && userPlayer.id === turn && 'Your turn'}
          </PlayerContent>
          {/* <PlayerTimer /> */}
        </Player>
      </div>

      <div className={bem('side', '2')} style={sideStyle}>
        <Player
          turn={opponentPlayer.id === turn}
          layout={playerLayout}
          disconnected={!opponentPlayer.connected && gamePhase === 'playing'}
          onClick={
            opponentPlayer.userId && opponentPlayer.userId !== 'anon'
              ? () => {
                  openModal('user-info', {
                    extra: { userId: opponentPlayer.userId },
                  });
                }
              : undefined
          }
          {...{ size }}
        >
          <PlayerGem type={opponentPlayer.colour} />
          <PlayerContent
            name={opponentPlayer.name}
            country={opponentPlayer.country}
            score={opponentPlayer.score}
          >
            {getOpponentMsg()}
          </PlayerContent>
          {opponentPlayer.rejoin &&
          opponentPlayer.rejoin > 0 &&
          gamePhase !== 'over' ? (
            <PlayerTimer start={opponentPlayer.rejoin} />
          ) : null}
        </Player>

        <div className={bem('action')}>{renderAction()}</div>
      </div>
    </div>
  );
};

export default GameInterface;
