import React, { useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useClipboard } from 'use-clipboard-copy';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel, { PanelClose } from '@/ui-components/Panel';
import Input from '@/ui-components/Input';
import Icon from '@/ui-components/Icon';
import Loading from '@/ui-components/Loading';
import { Player, PlayerGem, PlayerContent } from '@/ui-components/Player';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';
import useBreakpoint from '@/use/useBreakpoint';

import { gameIdState } from '@/state/gameState';
import { userPlayerState, opponentPlayerState } from '@/state/playersState';

const InviteGameModal: React.FC = () => {
  const { bem } = useBem('game-modal');
  const [isCopied, setIsCopied] = useState(false);
  const userPlayer = {
    name: useRecoilValue(userPlayerState.name),
    colour: useRecoilValue(userPlayerState.colour),
  };

  const opponentPlayer = {
    name: useRecoilValue(opponentPlayerState.name),
    colour: useRecoilValue(opponentPlayerState.colour),
  };

  const { breakpoint } = useBreakpoint();
  const gameId = useRecoilValue(gameIdState);
  const [isOpen, _, props] = useModal('game-invite');
  const { copy, target } = useClipboard({
    selectOnCopy: true,
    onSuccess: () => {
      setIsCopied(true);
    },
  });

  const onRequestClose = () => {
    props?.onRequestClose?.();
    setIsCopied(false);

    // Fix Android chrome having "share, copy ..." tooltip after modal closed
    target.current.blur();
  };

  const size = !breakpoint.l && !breakpoint.d ? 'small' : 'default';

  const handleSelect = (e: any) => e.target.select();

  const renderContent = () => {
    if (!gameId) {
      return <Loading />;
    }

    return (
      <div className={bem('wrap')}>
        <Typography className={bem('h')} variant="l" component="div">
          Waiting for player to join
        </Typography>

        <Input
          value={`${window.location.origin}/${gameId}`}
          readOnly
          variant="xs"
          ref={target}
          className={bem('share')}
          onFocus={handleSelect}
          onClick={handleSelect}
          suffix={
            <Icon
              name={isCopied ? 'tick' : 'copy'}
              onClick={copy}
              style={{ width: '12px' }}
            />
          }
        />

        <div className={bem('players')}>
          <Player turn loading={!userPlayer.name} {...{ size }}>
            <PlayerGem type={userPlayer?.colour} />
            <PlayerContent name={userPlayer?.name} />
          </Player>

          <Typography className={bem('vs')} variant="l">
            VS
          </Typography>

          <Player turn loading={!!opponentPlayer.name} {...{ size }}>
            <PlayerGem type={opponentPlayer?.colour} />
            <PlayerContent name={opponentPlayer?.name} />
          </Player>
        </div>
      </div>
    );
  };

  return (
    <Modal isCentered {...props} {...{ isOpen, onRequestClose }}>
      <Panel className={bem('')}>
        <PanelClose onClick={onRequestClose} />

        {renderContent()}
      </Panel>
    </Modal>
  );
};

export default InviteGameModal;
