import React from 'react';
import { useRecoilValue } from 'recoil';

import Modal from '@/ui-components/Modal';
import Typography from '@/ui-components/Typography';
import Panel, { PanelClose } from '@/ui-components/Panel';
import { Player, PlayerGem, PlayerContent } from '@/ui-components/Player';

import useBem from '@/use/useBem';
import useModal from '@/use/useModal';
import { userPlayerState, opponentPlayerState } from '@/state/playersState';
import useBreakpoint from '@/use/useBreakpoint';

const RandomGameModal: React.FC = () => {
  const { bem } = useBem('game-modal');

  const userPlayer = {
    score: useRecoilValue(userPlayerState.score),
    name: useRecoilValue(userPlayerState.name),
    colour: useRecoilValue(userPlayerState.colour),
    country: useRecoilValue(userPlayerState.country),
  };

  const opponentPlayer = {
    score: useRecoilValue(opponentPlayerState.score),
    name: useRecoilValue(opponentPlayerState.name),
    colour: useRecoilValue(opponentPlayerState.colour),
    country: useRecoilValue(opponentPlayerState.country),
  };

  const { breakpoint } = useBreakpoint();
  const [isOpen, _, props] = useModal('game-random');

  const size = !breakpoint.l && !breakpoint.d ? 'small' : 'default';

  return (
    <Modal isCentered {...props} {...{ isOpen }}>
      <Panel className={bem('')}>
        <PanelClose onClick={props?.onRequestClose} />
        <div className={bem('wrap')}>
          <Typography className={bem('h')} variant="l" component="div">
            Searching for a player
          </Typography>

          <div className={bem('players')}>
            <Player turn loading={!userPlayer.name} {...{ size }}>
              <PlayerGem type={userPlayer.colour} />
              <PlayerContent
                name={userPlayer.name}
                country={userPlayer.country}
                score={userPlayer.score}
              />
            </Player>

            <Typography className={bem('vs')} variant="l">
              VS
            </Typography>

            <Player turn loading={!opponentPlayer.name} {...{ size }}>
              <PlayerGem type={opponentPlayer.colour} />
              <PlayerContent
                name={opponentPlayer.name}
                country={opponentPlayer.country}
                score={opponentPlayer.score}
              />
            </Player>
          </div>
        </div>
      </Panel>
    </Modal>
  );
};

export default RandomGameModal;
