import React from 'react';
import { Link } from 'react-router-dom';

export interface IButtonBaseProps {
  /**
   * The URL to link to when the button is clicked. If defined, an Link element will be used as the root node.
   */
  to?: string;

  /**
   * If true, the button will be disabled.
   */
  disabled?: boolean;
  className?: string;

  /**
   * Button type
   */
  type?: String;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

const BaseButton: React.FC<IButtonBaseProps> = ({
  children,
  to,
  className = '',
  disabled,
  onClick = () => {},
  type,
  ...rest
}) => {
  let Component: any = 'div';

  const props = { onClick } as any;

  if (to) {
    if (to?.startsWith('http')) {
      props.href = to;
      Component = 'a';
    } else {
      Component = Link;
      props.disabled = disabled;
      props.to = to;
    }
  } else {
    Component = type ? 'button' : 'div';
  }

  if (Component === 'button') {
    props.type = type;
  }

  return (
    <Component className={`${className} base-button`} {...props} {...rest}>
      {children}
    </Component>
  );
};

export default BaseButton;
