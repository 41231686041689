import React from 'react';
import { useRecoilValue } from 'recoil';

import Modal from '@/ui-components/Modal';
import Flag from '@/ui-components/Flag';
import Typography from '@/ui-components/Typography';
import Button from '@/ui-components/Button';
import ButtonGroup from '@/ui-components/ButtonGroup';
import Gem from '@/ui-components/Gem';

import useLayout from '@/use/useLayout';
import useBem from '@/use/useBem';
import useModal from '@/use/useModal';
import { useRematchGame, useFindGame } from '@/use/useGame';

import {
  winnerIdState,
  endgameReasonState,
  endgameScoreChangeState,
} from '@/state/boardState';
import { gameTypeState } from '@/state/gameState';
import { userPlayerState, opponentPlayerState } from '@/state/playersState';

const EndgameModal = () => {
  const { bem } = useBem('endgame');
  const { layout } = useLayout();
  const [isOpen, setModal, props] = useModal('endgame');
  const hasAnimation = !!(props?.extra?.animate && isOpen);

  const userPlayer = {
    id: useRecoilValue(userPlayerState.id),
    rematch: useRecoilValue(userPlayerState.rematch),
    colour: useRecoilValue(userPlayerState.colour),
    score: useRecoilValue(userPlayerState.score),
  };

  const opponentPlayer = {
    rematch: useRecoilValue(opponentPlayerState.rematch),
  };

  const winnerId = useRecoilValue(winnerIdState);
  const gameType = useRecoilValue(gameTypeState);
  const endgameReason = useRecoilValue(endgameReasonState);
  const endgameScoreChange = useRecoilValue(endgameScoreChangeState);
  const rematchGame = useRematchGame();
  const findGame = useFindGame();

  const victory = userPlayer?.id === winnerId;
  const tie = endgameReason === 'tie';

  const title = tie ? 'Tie!' : victory ? 'Victory!' : 'Defeat!';
  const flag = tie ? 'normal' : victory ? 'normal' : 'ripped';

  const reasonMap = {
    won: 'By connect 4',
    resign: victory ? 'Opponent resigned' : 'You resigned',
    abandon: victory ? 'Opponent abandoned the game' : 'Game abandoned',
    tie: 'You found your match!',
  };

  const reason = endgameReason ? reasonMap?.[endgameReason] : null;

  const renderRematch = () => {
    if (!opponentPlayer?.rematch && !userPlayer?.rematch) {
      return null;
    }

    return (
      <Typography variant="m">
        {userPlayer?.rematch
          ? 'Rematch request sent'
          : 'Your opponent wants to play again'}
      </Typography>
    );
  };

  const renderScore = () => {
    if (endgameScoreChange === undefined) {
      return null;
    }

    return (
      <Typography variant="s" component="div" className={bem('score')}>
        {userPlayer.score}{' '}
        <span
          className={bem(
            'score-change',
            endgameScoreChange > 0 ? 'pos' : 'neg',
          )}
        >
          {endgameScoreChange > 0 ? '+' : '-'}
          {Math.abs(endgameScoreChange)}
        </span>
      </Typography>
    );
  };

  return (
    <Modal
      isCentered
      onRequestClose={setModal.close}
      contentClassName={bem('', [layout, { hasAnimation }])}
      inGame
      padding="small"
      {...props}
      {...{ isOpen }}
    >
      <div className={bem('content')}>
        <div className={bem('top')}>
          <div className={bem('gem-wrap')}>
            <div className={bem('gem')}>
              <Gem type={userPlayer.colour} halo={victory} state="selected" />
            </div>
          </div>
          <div className={bem('title')}>
            <Typography variant="bevel">{title}</Typography>
            {reason && (
              <Typography mb="medium" variant="m">
                {reason}
              </Typography>
            )}
            {renderScore()}
          </div>
          <Flag className={bem('flag')} type={flag} />
        </div>

        <div className={bem('again')}>{renderRematch()}</div>
        <ButtonGroup className={bem('buttons')}>
          <Button
            size="medium"
            color="red"
            onClick={() => {
              rematchGame();
            }}
            loading={!!userPlayer.rematch}
          >
            Rematch
          </Button>
          {gameType === 'random' && (
            <Button
              size="medium"
              color="blue"
              onClick={() => {
                findGame('random');
              }}
            >
              Find new game
            </Button>
          )}
          <Button size="medium" onClick={setModal.close}>
            Back to board
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default EndgameModal;
