import { atom } from 'recoil';

import { IUser } from '@/framework/user/types';

export default {
  username: atom<IUser['username'] | undefined>({
    key: 'username',
    default: undefined,
  }),

  country: atom<IUser['country'] | undefined>({
    key: 'country',
    default: undefined,
  }),

  id: atom<IUser['id'] | undefined>({
    key: 'id',
    default: undefined,
  }),

  losses: atom<IUser['losses'] | undefined>({
    key: 'losses',
    default: undefined,
  }),

  score: atom<IUser['score'] | undefined>({
    key: 'score',
    default: undefined,
  }),

  wins: atom<IUser['wins'] | undefined>({
    key: 'wins',
    default: undefined,
  }),

  total: atom<IUser['total'] | undefined>({
    key: 'total',
    default: undefined,
  }),

  activeGem: atom<IUser['activeGem'] | undefined>({
    key: 'activeGem',
    default: undefined,
  }),
};
