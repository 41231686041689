import { selector, DefaultValue } from 'recoil';

import userState from './userState';
import { IUser } from './types';

export const loggedIn = selector({
  key: 'loggedIn',
  get: ({ get }) => get(userState.username) !== undefined,
});

export const user = selector<IUser>({
  key: 'userSelector',
  get: ({ get }) => ({
    username: get(userState.username),
    country: get(userState.country),
    id: get(userState.id),
    losses: get(userState.losses),
    score: get(userState.score),
    wins: get(userState.wins),
    total: get(userState.total),
    activeGem: get(userState.activeGem),
  }),
  set: ({ set }, newUser) => {
    const isReset = newUser instanceof DefaultValue;

    set(userState.username, isReset ? newUser : newUser.username);
    set(userState.country, isReset ? newUser : newUser.country);
    set(userState.id, isReset ? newUser : newUser.id);
    set(userState.losses, isReset ? newUser : newUser.losses);
    set(userState.score, isReset ? newUser : newUser.score);
    set(userState.wins, isReset ? newUser : newUser.wins);
    set(userState.total, isReset ? newUser : newUser.total);
    set(userState.activeGem, isReset ? newUser : newUser.activeGem);
  },
});
