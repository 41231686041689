import React from 'react';
import classNames from 'classNames';

import Typography from '@/ui-components/Typography';

import useBem from '@/use/useBem';

export type FieldGroupType = {
  className?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
};

const FieldGroup = ({ children, className = '', label }: FieldGroupType) => {
  const { bem } = useBem('field-group');

  return (
    <div className={classNames([bem(''), className])}>
      {label && (
        <Typography variant="m" className={bem('label')}>
          {label}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default FieldGroup;
