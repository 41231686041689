import React, { useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { Container, Sprite } from 'react-pixi-fiber';

import useLoader from '@/game/useLoader';
import { GemType, GemState } from '@/framework/gem';

export type Props = {
  type?: GemType;
  state?: GemState;
  width: number;
  height: number;
};

const Gem = ({ width, height, type, state = 'normal' }: Props) => {
  const { loader, loaded } = useLoader();

  if (!loaded) return null;
  if (!type) return null;

  const name = useMemo(() => {
    if (state === 'normal') {
      return `gem-${type}`;
    }

    return `gem-${type}-${state}`;
  }, [type, state]);

  const texture = loader.resources[name].texture;
  const { width: textureW, height: textureH } = texture;
  const ratio = textureW / textureH;
  const cellWidth = width * ratio;

  const x = (width - cellWidth) / 2;
  const anchor = new PIXI.Point(0, 0);

  return (
    <Container>
      <Sprite width={cellWidth} {...{ anchor, texture, height, x }} />
    </Container>
  );
};

export default Gem;
