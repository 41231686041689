import React, { useMemo } from 'react';
import classNames from 'classNames';

import BaseButton, { IButtonBaseProps } from '@/ui-components/BaseButton';
import Typography, { ITypographyProps } from '@/ui-components/Typography';

import useBem from '@/use/useBem';
import useBreakpoint from '@/use/useBreakpoint';
interface Props extends IButtonBaseProps {
  elevation?: 0 | 5;
  icon?: React.ReactNode;
}

const SquareButton: React.FC<Props> = ({
  children,
  elevation = 0,
  className = '',
  icon,
  ...rest
}) => {
  const { bem } = useBem('square-button');
  const { breakpoint } = useBreakpoint();

  const typeSize = useMemo<ITypographyProps['variant']>(() => {
    // if (breakpoint.l) return 'l';
    // if (breakpoint.t) return 'm';
    // if (breakpoint.m) return 'xs';

    return 's';
  }, [breakpoint]);

  // let useMemo: ITypographyProps['variant'] = 'm';

  // if (breakpoint.l) typeSize = 'l';
  // if (breakpoint.t) typeSize = 'm';
  // if (breakpoint.m) typeSize = 'xs';

  return (
    <BaseButton
      {...rest}
      className={classNames([bem('', [`elevation-${elevation}`]), className])}
    >
      <div className={bem('button')}>
        <div className={bem('icon')}>{icon}</div>
      </div>

      {children && (
        <Typography
          variant={typeSize}
          component="div"
          className={bem('content')}
        >
          {children}
        </Typography>
      )}
    </BaseButton>
  );
};

export default SquareButton;
