import React, { useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { Sprite, Container } from 'react-pixi-fiber';
import { Spring } from 'react-spring';
import { useRecoilValue } from 'recoil';

import useLoader from '@/game/useLoader';

import { IPlayer2 } from '@/framework/player';
import { GemState } from '@/framework/gem';

import {
  boardState,
  winningComboState,
  gamePhaseState,
} from '@/state/boardState';

import { userPlayerState, opponentPlayerState } from '@/state/playersState';

import Animated from './Animated';
import Gem from './Gem';

import { mapPidToColor } from './utils';

export type Props = {
  width: number;
  height: number;
  y: number;

  /** Needs to have a correct zIndex, so that gems don't render being the cells */
  zIndex: number;
  /** Child of cell - ie. Gem */
  children?: React.ReactNode;
  cIndex: number;
  rIndex: number;
};

const Cell = ({ width, height, zIndex, y, cIndex, rIndex }: Props) => {
  const { loader, loaded } = useLoader();

  const cellState = useRecoilValue(boardState[cIndex][rIndex]);
  const winningCombo = useRecoilValue(winningComboState);
  const gamePhase = useRecoilValue(gamePhaseState);

  const userPlayer = {
    id: useRecoilValue(userPlayerState.id),
    colour: useRecoilValue(userPlayerState.colour),
  };

  const opponentPlayer = {
    id: useRecoilValue(opponentPlayerState.id),
    colour: useRecoilValue(opponentPlayerState.colour),
  };

  const gemState: GemState = useMemo(() => {
    if (winningCombo && gamePhase === 'over') {
      if (
        winningCombo?.some((cell) => cell[0] === cIndex && cell[1] === rIndex)
      ) {
        return 'selected';
      } else {
        return 'broken';
      }
    }
    return 'normal';
  }, [winningCombo, gamePhase]);

  const gemColor = useMemo(() => {
    if (!cellState) {
      return;
    }

    return mapPidToColor(cellState.pid, userPlayer, opponentPlayer);
  }, [userPlayer, opponentPlayer, cellState]);

  const active = !!cellState;

  const dropFrom = y + height;

  const from = { y: -dropFrom };
  const to = {
    y: 0,
  };

  const config = {
    bounce: 0.3,
    mass: 1,
    tension: 200,
    friction: 6,
  };

  if (!loaded) return null;

  const texture = loader.resources['hole'].texture;
  const { width: textureW, height: textureH } = texture;
  const ratio = textureW / textureH;
  const cellWidth = width * ratio;
  const x = (width - cellWidth) / 2;
  const anchor = new PIXI.Point(0, 0);

  return (
    <Container {...{ zIndex, y, x }}>
      <Sprite width={cellWidth} {...{ anchor, texture, height }} />

      {active && (
        <Spring {...{ config, from, to }}>
          {(styles) => (
            <Animated.Container zIndex={10} {...styles}>
              <Gem {...{ width, height }} type={gemColor} state={gemState} />
            </Animated.Container>
          )}
        </Spring>
      )}
    </Container>
  );
};

export default Cell;
