import React from 'react';
import classNames from 'classNames';

import Typography from '@/ui-components/Typography';
import Loading from '@/ui-components/Loading';
import BaseButton, { IButtonBaseProps } from '@/ui-components/BaseButton';

import useBem from '@/use/useBem';
interface Props extends IButtonBaseProps {
  /** The size of the button */
  size?: 'large' | 'medium';

  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'red' | 'blue' | 'brown';

  /** The sub text under content. */
  sub?: React.ReactNode;

  /** Add disabled style to the button */
  disabled?: boolean;

  /** Add loading style to the button */
  loading?: boolean;
}

const Button: React.FC<Props> = ({
  size = 'medium',
  color = 'brown',
  disabled,
  loading,
  children,
  className = '',
  sub,
  ...rest
}) => {
  const { bem } = useBem('button');

  const isDisabled = loading || disabled;

  return (
    <BaseButton
      {...rest}
      className={classNames([
        bem('', [{ disabled: isDisabled }, size, color]),
        className,
      ])}
    >
      <Typography variant={'xl'} component="span" className={bem('content')}>
        {loading ? <Loading /> : children}
      </Typography>
      {sub ? (
        <Typography variant={'m'} component="span" className={bem('sub')}>
          {sub}
        </Typography>
      ) : null}
    </BaseButton>
  );
};

export default Button;
