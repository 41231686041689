import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Routes, Route } from 'react-router-dom';

import Menu from '@/ui-components/Menu';
import { LeaderboardModal, LeaderboardSide } from '@/ui-components/Leaderboard';
import Overlay from '@/ui-components/Overlay';
import Nav from '@/ui-components/Nav';
import EndgameModal from '@/ui-components/EndgameModal';
import Login from '@/ui-components/Login';
import Game from '@/ui-components/Game';
import GameInterface from '@/ui-components/GameInterface';
import AccountModal from '@/ui-components/AccountModal';
import ForgotPasswordModal from '@/ui-components/ForgotPasswordModal';
import RandomGameModal from '@/ui-components/RandomGameModal';
import ConnectModal from '@/ui-components/ConnectModal';
import GameNotFoundModal from '@/ui-components/GameNotFoundModal';
import ResignModal from '@/ui-components/ResignModal';
import ConfirmExit from '@/ui-components/ConfirmExit';
import InviteGameModal from '@/ui-components/InviteGameModal';
import ResetPassword from '@/ui-components/ResetPassword';
import UserInfoModal from '@/ui-components/UserInfoModal';

import * as profile from '@/api/profile.service';

import useLayout from '@/use/useLayout';
import { pidState, setPid } from '@/use/usePidState';

import { checkLogin } from '@/framework/user';

const App = () => {
  const { layout } = useLayout();
  const pid = useRecoilValue(pidState);

  /** Get Player ID on component mount */
  useEffect(() => {
    if (!pid) {
      profile.getPID().then(({ data }) => {
        setPid(data.pid);
      });
    }
  }, []);

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <>
      <Nav />
      {layout === 'desktop' && <LeaderboardSide />}

      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<AccountModal />} />
        <Route path="/forgot-password" element={<ForgotPasswordModal />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route path="/:gameId" element={<Game />} />
      </Routes>

      <Overlay />
      <GameInterface />

      <LeaderboardModal />

      <RandomGameModal />
      <InviteGameModal />

      <ConnectModal />
      <GameNotFoundModal />
      <EndgameModal />
      <ResignModal />
      <ConfirmExit />
      <UserInfoModal />
    </>
  );
};

export default App;
