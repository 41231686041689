export const textures = {
  bg: require(`@/assets/images/board/bg.jpg`).src,
  'bg-seamless': require(`@/assets/images/board/bg-seamless.jpg`).src,
  'corner-bottom-left': require(`@/assets/images/board/corner-bottom-left.png`)
    .src,
  'corner-bottom-right': require(`@/assets/images/board/corner-bottom-right.png`)
    .src,
  'corner-top-left': require(`@/assets/images/board/corner-top-left.png`).src,
  'corner-top-right': require(`@/assets/images/board/corner-top-right.png`).src,
  'plank-bottom': require(`@/assets/images/board/plank-bottom.png`).src,
  'plank-left': require(`@/assets/images/board/plank-left.png`).src,
  'plank-right': require(`@/assets/images/board/plank-right.png`).src,
  'plank-top': require(`@/assets/images/board/plank-top.png`).src,
  hole: require(`@/assets/images/board/hole.png`).src,

  'gem-blue-broken': require(`@/assets/images/gems/gem-blue-broken@2x.png`).src,
  'gem-blue-selected': require(`@/assets/images/gems/gem-blue-selected@2x.png`)
    .src,
  'gem-blue': require(`@/assets/images/gems/gem-blue@2x.png`).src,
  'gem-red-broken': require(`@/assets/images/gems/gem-red-broken@2x.png`).src,
  'gem-red-selected': require(`@/assets/images/gems/gem-red-selected@2x.png`)
    .src,
  'gem-red': require(`@/assets/images/gems/gem-red@2x.png`).src,
  'gem-neutral-broken': require(`@/assets/images/gems/gem-neutral@2x.png`).src,
  'gem-neutral-selected': require(`@/assets/images/gems/gem-neutral@2x.png`)
    .src,
  'gem-neutral': require(`@/assets/images/gems/gem-neutral@2x.png`).src,
};
