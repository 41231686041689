import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import * as cookies from 'browser-cookies';

export const pidState = atom<string | null>({
  key: 'pidState',
  default: cookies.get('pid'),
});

export function setPid(pid: string) {
  setRecoil(pidState, pid);
  cookies.set('pid', pid);
}
