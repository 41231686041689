import React from 'react';
import classNames from 'classNames';

import useBem from '@/use/useBem';

type LoadingProps = {
  className?: string;
};

const Loading = ({ className = '' }: LoadingProps) => {
  const { bem } = useBem('loading');

  return (
    <div className={classNames([bem(''), className])}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Loading;
