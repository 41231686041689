import React from 'react';
import classNames from 'classNames';

import useBem from '@/use/useBem';

type Props = {
  value: any;
  onChange: (a: string) => void;
  children: React.ReactNode;
  className?: string;
};

const Tabs: React.FC<Props> = (props) => {
  const { children: childrenProp, onChange, value, className } = props;
  const { bem } = useBem('tabs');

  const children = React.Children.map(childrenProp, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const childValue = child.props.value === undefined ? index : child.props.value;
    const selected = value === childValue;

    return React.cloneElement(child, {
      selected,
      onChange,
      value: childValue,
    });
  });

  return <div className={classNames([bem(''), className])}>{children}</div>;
};

export default Tabs;
