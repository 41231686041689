import React from 'react';
import classNames from 'classNames';

import useBem from '@/use/useBem';

export type PanelProps = React.HTMLAttributes<HTMLDivElement>;

const Panel = ({ children, className = '' }: PanelProps) => {
  const { bem } = useBem('panel');

  return (
    <div className={classNames([bem(''), className])}>
      <div className={bem('content')}>{children}</div>
      <div className={bem('borders')}>
        <div className={bem('border', 'left')} />
        <div className={bem('border', 'right')} />
        <div className={bem('border', 'top')} />
        <div className={bem('border', 'bottom')} />
      </div>
    </div>
  );
};

export default Panel;
