import { useMemo } from 'react';
import useLayout from '@/use/useLayout';
import useBoard from '@/use/useBoard';
import { useWindowSize } from '@react-hook/window-size';

const useGrid = () => {
  const [ww] = useWindowSize();
  const board = useBoard();
  const { layout } = useLayout();
  const padding = layout === 'desktop' ? 40 : 0;
  const initialWidth = 600;
  const initialHeight = 540;

  const minimumSideWidth = useMemo(() => {
    if (ww < 600) {
      return 120;
    }

    if (ww < 700) {
      return 140;
    }

    return 160;
  }, [ww]);

  const minimumSideHeight = 110;

  const boundaries = useMemo(() => {
    if (layout === 'portrait') {
      return {
        width: board.width - padding,
        height: board.height - padding - minimumSideHeight * 2,
      };
    }

    return {
      width: board.width - padding - minimumSideWidth * 2,
      height: board.height - padding,
    };
  }, [layout, board.width, board.height]);

  const scale = Math.min(
    Math.min(
      boundaries.width / initialWidth,
      boundaries.height / initialHeight,
      1,
    ),
  );

  const width = initialWidth * scale;
  const height = initialHeight * scale;

  const x = -width / 2;
  const y = -height / 2;

  return {
    scale,
    x,
    y,
    width,
    height,
    initialWidth,
    initialHeight,
  };
};

export default useGrid;
