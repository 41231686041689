import React from 'react';
import { useRecoilValue } from 'recoil';

import Button from '@/ui-components/Button';
import Centered from '@/ui-components/Centered';
import ButtonGroup from '@/ui-components/ButtonGroup';
import Logo from '@/ui-components/Logo';
import Page from '@/ui-components/Page';

import useBreakpoint from '@/use/useBreakpoint';
import useBem from '@/use/useBem';

import { useFindGame } from '@/use/useGame';
import { isMenuOpenState } from '@/use/useMenuState';

const Menu = () => {
  const { breakpoint } = useBreakpoint();
  const { bem } = useBem('menu');
  const findGame = useFindGame();
  const isMenuOpen = useRecoilValue(isMenuOpenState);

  if (!isMenuOpen) {
    return null;
  }

  return (
    <Page>
      <Centered>
        <div className={bem('')}>
          <div className={bem('wrap')}>
            {breakpoint.d && (
              <div className={bem('logo')}>
                <Logo />
              </div>
            )}

            <ButtonGroup className={bem('items')}>
              <Button
                onClick={() => {
                  findGame('random');
                }}
                size="large"
                color="red"
              >
                Find opponent
              </Button>
              <Button
                onClick={() => {
                  findGame('invite');
                }}
                size="large"
                color="blue"
              >
                Invite a friend
              </Button>
              {/* <Button to="/endgame" size="large" sub="Same screen">
              Pass & Play
            </Button> */}
            </ButtonGroup>
          </div>
        </div>
      </Centered>
    </Page>
  );
};

export default Menu;
