import React from 'react';

const logo50 = require('@/assets/images/logo.png?size=50');
const logo50x2 = require('@/assets/images/logo.png?size=100');
const logo70 = require('@/assets/images/logo.png?size=70');
const logo70x2 = require('@/assets/images/logo.png?size=140');

interface Logo {
  size: 'default' | 'small';
}

const Logo = (props: Logo) => {
  const { size } = props;

  const src = size === 'small' ? logo50 : logo70;
  const srcSet = size === 'small' ? logo50x2 : logo70x2;

  return <img src={src} srcSet={`${srcSet} 2x`} />;
};

Logo.defaultProps = {
  size: 'default',
};

export default Logo;
