import React from 'react';
import Flag from 'react-flagkit';

import Typography from '@/ui-components/Typography';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';

import { usePlayer } from './Player';

interface Props {
  name?: string;
  score?: number;
  country?: string;
  children?: React.ReactNode;
}

const PlayerContent = (props: Props) => {
  const { country, name, score, children } = props;
  const { bem } = useBem('player');
  const { loading, size } = usePlayer();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={bem('text')}>
      <div className={bem('name')}>
        {country && (
          <span className={bem('flag')}>
            <Flag country={country.toUpperCase()} size={15} />
          </span>
        )}
        <Typography variant={size === 'default' ? 'm' : 's'} component="span">
          {name}
        </Typography>
      </div>
      {score && (
        <Typography variant="s" className={bem('score')} component="div">
          ({score})
        </Typography>
      )}

      {children && (
        <Typography variant="xs" className={bem('content')} component="div">
          {children}
        </Typography>
      )}
    </div>
  );
};

export default PlayerContent;
