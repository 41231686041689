import React from 'react';

import { GemType } from '@/framework/gem';

import Gem from '@/ui-components/Gem';
import Loading from '@/ui-components/Loading';

import useBem from '@/use/useBem';

import { usePlayer } from './Player';

interface Props {
  type?: GemType;
}

const PlayerGem = (props: Props) => {
  const { type } = props;
  const { loading, size, turn, disconnected } = usePlayer();
  const { bem } = useBem('player');

  const state = turn ? 'selected' : 'normal';

  return (
    <div className={bem('gem')}>
      <Gem type={loading ? 'neutral' : type} {...{ size, state }} />
      {disconnected && <Loading className={bem('gem-loading')} />}
    </div>
  );
};

export default PlayerGem;
